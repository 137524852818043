import './App.css';
// import Homepage from './components/ChocoTreats/Homepage';
// import Homepage from './components/Phantomvideos/hompage';
import Homepage from './components/Landingpage/Hompage';

function App() {
  return (
    <div className="App">
       <Homepage/>
    </div>
  );
}

export default App;
