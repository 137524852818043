import React from "react";
import Marquee from "react-fast-marquee";

const Hero = () => {
  return (
    <div
      className="w-full h-[80vh] bg-no-repeat relative"
      style={{
        backgroundImage:
          "url('https://wallpaperaccess.com/full/385736.jpg')",
        backgroundSize: "cover",
      }}
    >
      {/* Background Overlay */}
      <div className="absolute inset-0 w-full h-full bg-black opacity-50"></div>

      {/* Hero Content */}
      <div className="absolute z-50 top-1/4 left-5 md:left-20 px-4 md:px-0">
        <div className="w-full md:w-2/3">
          <h1 className="text-5xl md:text-5xl lg:text-7xl font-BebasNeue text-white leading-tight">
            Immerse Yourself in India's Culinary Delights
          </h1>
          <h2 className="text-3xl md:text-4xl lg:text-3xl font-advertising text-white mt-4">
            Experience the Flavors of India
          </h2>
          <div className="flex flex-col md:flex-row gap-5 md:gap-10 mt-10">
            <button className="bg-green-400 border border-black text-sm md:text-md font-semibold px-10 md:px-20 py-3 md:py-4 text-black rounded-full">
              Get Your Order
            </button>
            <button className="bg-green-400 border border-black text-sm md:text-md font-semibold px-10 md:px-20 py-3 md:py-4 text-black rounded-full">
              Learn More
            </button>
          </div>
        </div>
      </div>

      {/* Marquee */}
      <Marquee autoFill className="bg-green-400 text-black p-2 fixed top-0 z-[35]">
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🚀 Get 10% Discount on every meal
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🌟 Get 10% Discount on every meal
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🔥 Get 10% Discount on every meal
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🚀 Get 10% Discount on every meal
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🌟 Get 10% Discount on every meal
        </span>
        <span className="mx-4 text-sm md:text-lg font-semibold">
          🔥 Get 10% Discount on every meal
        </span>
      </Marquee>
    </div>
  );
};

export default Hero;
