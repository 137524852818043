import React, { useState, useEffect } from "react";
import Hero from "./Hero";
import ReactGA from "react-ga4";
import { IoMdClose } from "react-icons/io";

const Popup = () => {
  return (
    <div className="fixed  inset-0 bg-black bg-opacity-50 flex justify-center items-center z-[1000]">
      <div className=" md:w-[80vh] relative overflow-hidden w-[50vh] h-[50vh]  md:h-[80vh] rounded-lg shadow-lg">
        <img className="w-full h-full" src="/foodpopup2.jpg" alt="Popup" />
          <span className="absolute bg-white rounded-full p-2 top-5 right-5"><IoMdClose className="text-xl"/></span>
      </div>
    </div>
  );
};

const Homepage = () => {
  useEffect(() => {
    ReactGA.initialize("G-SHPEYX0F7H");
    ReactGA.send("pageview");
  }, []);

  const handleonclick = () => {
    ReactGA.event({
      category: "CTA",
      action: "Claim Now Button Clicked",
      label: "Claim Bonus",
    });
    window.open("https://bit.ly/ggnowww", "_blank");
  };
  const [showPopup, setShowPopup] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowPopup(true);
    }, 3000);

    return () => clearTimeout(timer);
  }, []);

  return (
    <div onClick={handleonclick}>
      {showPopup && (
        <div>
          <Popup />
        </div>
      )}
      <Hero />
      <div
        onClick={handleonclick}
        className="bg-green-500 py-10 grid place-items-center"
      >
        <button className="bg-white border border-black md:px-52  px-20 rounded-full text-xl font-semibold py-3">
          Place your order now
        </button>
      </div>
      <div className="mt-20 grid place-items-center">
        <h2 className="md:text-5xl font-bold text-yellow-900">About us</h2>
        <p className="text-center md:text-3xl mt-10 md:w-2/3">
          At Phantombook, we're passionate about sharing the rich culinary
          heritage of India. Our chefs use traditional recipes and fresh
          ingredients to create authentic dishes that will transport you to the
          streets of Mumbai, Delhi, or Bengaluru.
        </p>
      </div>
      <div className="mt-20 grid place-items-center">
        <button className="md:px-44 border border-black px-20 md:py-4 py-2 bg-green-400 rounded-full text-xl font-semibold">
          Message on WhatsApp Now
        </button>
      </div>
      <div className="w-full h-auto bg-black md:flex justify-between items-center p-10 mt-32">
        <h1 className="text-white md:text-xl text-sm">
          Copyright © 2024 Phantombook - All Rights Reserved.
        </h1>
        <h1 className="text-white text-xl md:mt-0 mt-5">Powered by phantom</h1>
      </div>
    </div>
  );
};

export default Homepage;
